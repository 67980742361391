.m-controlList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.4rem;
  margin-bottom: 0.6rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }
}
