.m-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  letter-spacing: 1.5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin-top: 2.5rem;
    font-size: 1.1rem;
  }

  &__heroLink {
    text-decoration: none;
    color: #c54646;
    margin-top: 0.1rem;
    @media screen and (max-width: 600px) {
      margin-top: 0.2rem;
    }
  }
}
