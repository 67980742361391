.m-container {
  width: 90%;

  margin: 0 auto;

  @include media-small-tablet {
  }
  @include media-tablet {
  }
  @include media-small-desktop {
  }
  @include media-desktop {
  }
}
