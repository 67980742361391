.a-controlButton {
  outline: none;
  color: white;
  background-color: $primary-color;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0.4rem 0.7rem;

  @media screen and (max-width: 600px) {
    margin: 0.6rem 1rem;
  }

  &.-timerControl {
    margin: 0 0.4rem;
    @media screen and (max-width: 600px) {
      margin: 0 0.6rem;
    }
  }
}
